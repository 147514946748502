import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import '../assets/sparkles-effect/particles.js';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { error } from 'util';

declare var particleStarter: any;
declare var particlesJS: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Fairy Lullaby';

  constructor() {
    particlesJS.load('particles-js', "../assets/sparkles-effect/particlesjs-config.json", function() {
      // Loaded successfully!
    });
  }
}
