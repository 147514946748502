import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationBuilder } from '@angular/platform-browser/animations/src/animation_builder';
import { SubscribeResultSuccessComponent } from './components/subscribe-result-success/subscribe-result-success.component';
import { SubscribeResultErrorComponent } from './components/subscribe-result-error/subscribe-result-error.component';
import { RouterModule, Routes } from '@angular/router';
import { NavigationBarDesktopComponent } from './components/navigation-bar-desktop/navigation-bar-desktop.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './components/home/home.component';
import { VideoListComponent } from './components/video-list/video-list.component';
import { NavigationBarDesktopSpacerComponent } from './components/navigation-bar-desktop-spacer/navigation-bar-desktop-spacer.component';
import { YouTubeService } from './services/you-tube.service';
import { HttpClient } from '@angular/common/http/src/client';
import { MusicPageComponent } from './music-page/music-page.component';

const appRoutes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: { title: 'Home' }
  },
  /*{
    path: 'music',
    component: MusicPageComponent,
    data: { title: 'Music' }
  },*/
  /*{
    path: 'videos',
    component: VideoListComponent,
    data: { title: 'Videos' },
    resolve: {
      videoData: YouTubeService
    }
  },*/
  { path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: '**',
  redirectTo: '/home',
  pathMatch: 'full'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    SubscribeResultSuccessComponent,
    SubscribeResultErrorComponent,
    NavigationBarDesktopComponent,
    HomeComponent,
    MusicPageComponent,
    VideoListComponent,
    NavigationBarDesktopSpacerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    )
  ],
  providers: [YouTubeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
