import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Video } from '../classes/video';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {  } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class YouTubeService implements Resolve<Video> {

  private apiKey = "AIzaSyA6QHIUa2MrIBDqTxRNOn8Ozw5Ol7UAv7Y";
  constructor(private http: HttpClient) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this.getAllVideos();
  }

  getAllVideos(): Observable<Video> {
    return this.http.get<Video>("https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=10&playlistId=UU91zvxtvEPZ2oE9ZEh2TNaQ&key="+this.apiKey)
  }

}
