import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-subscribe-result-error',
  templateUrl: './subscribe-result-error.component.html',
  styleUrls: ['./subscribe-result-error.component.css'],
  animations: [
    trigger('resultState', [
      state('show', style({ opacity: 1 })),
      state('hide', style({ opacity: 0 })),
      transition('hide => show', animate('0.3s ease-out')),
      transition('show => hide', animate('0.3s ease-in'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class SubscribeResultErrorComponent implements OnInit {
  
  @Input() errorMessage = "Error adding email"
  @Input() state = 'hide'

  constructor() { }

  ngOnInit() {
  }

  hide() {
    if (this.state == 'show') {
      setTimeout(() => {
        this.state = 'hide'
      }, 2000);
    }
  }

}
