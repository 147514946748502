import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { YouTubeService } from '../../services/you-tube.service';
import { Video } from '../../classes/video';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VideoListComponent implements OnInit {

  public videos;

  constructor(private route: ActivatedRoute, private youtubeService: YouTubeService, private sanitizer: DomSanitizer, private app: ApplicationRef) {

  }

  ngOnInit() {
    this.videos = [];
    this.route.data.subscribe((data: { videoData: any }) => {
      data.videoData["items"].forEach(element => {
        let snippet = element["snippet"];
        this.videos.push(new Video(snippet["resourceId"]["videoId"], snippet["title"], snippet["description"], snippet["thumbnails"]["standard"]["url"], this.sanitizer));
      });
    });
  }

}
