import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { NewsEmailRecipient } from '../../news-email-recipient';
import { SubscribeResultSuccessComponent } from '../subscribe-result-success/subscribe-result-success.component';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { error } from 'util';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  title = 'Fairy Lullaby';

  subscriber: NewsEmailRecipient = {
    email: null
  }

  @ViewChild('result_success') successContainer;
  @ViewChild('result_error') errorContainer;
  @ViewChild('subscribe_button') subscribeButton: ElementRef;

  constructor(private db: AngularFirestore) {

  }

  validateEmail(email: string): boolean {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    console.log('email')
    console.log(re.test(email))
    return re.test(email)
  }

  onSubscribe(newSubscriber: NewsEmailRecipient): void {
    this.subscribeButton.nativeElement.focus()
    var success = this.successContainer
    var errorContainer = this.errorContainer

    if (newSubscriber.email == "" || newSubscriber.email == null || !this.validateEmail(newSubscriber.email)) {
      console.log('Error')
      this.subscribeButton.nativeElement.blur()
      this.errorContainer.errorMessage = "Please enter a valid email address"
      this.errorContainer.state = 'show'
      console.log(this.errorContainer.state)
      return
    } else {
      this.errorContainer.errorMessage = "There was an error adding your email"
    }

    var subscriberCollection = this.db.collection('news-email-subscriber')
    var date = new Date();
    console.log('adding')
    subscriberCollection.add({ email: newSubscriber.email, signed_up_date: date }).then(function(snapshot) {
      console.log(snapshot)
      success.state = 'show'
    }, function(error) {
      errorContainer.state = 'show'
      console.error(error);
    });
  }

}

